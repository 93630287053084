<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <banner-slider></banner-slider>
            </div>
        </div>
        <section class="blockElement innerPage-banner imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12"> 
                        <div class="breadCrum d-flex align-items-center justify-content-between">
                            <div class="bdPage">
                                <h2>News</h2>
                                <nav aria-label="breadcrumb mt-2">
                                    <ol class="breadcrumb mb-0">
                                        <li class="breadcrumb-item"><router-link class="green" to="/">Home</router-link></li>
                                        <li class="breadcrumb-item active" aria-current="page">News</li>
                                    </ol>
                                </nav>
                            </div>
                            <div class="rightSearch d-flex align-items-center">
                                <a href="javascript:void(0)" @click="() => movePage('All',All)" class="green_btn d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                                <!-- <form class="searchBar hideMobile">
                                    <div class="form-group position-relative inputIcon mb-0">
                                        <input type="text" class="form-control" placeholder="Search.." name="" /> 
                                        <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                    </div>
                                </form> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement mainNewsBlock imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-7" v-for="item,key in store.newsList.records.slice(0,1)" :key="key">
                        <div class="card-body topNews p-0" v-if="item.title">
                            <!-- <img class="w-100" src="../../../public/assets/images/dummy.webp" alt="News" title="News" v-if="item.imageLarge.includes('DEFAULT')" > -->
                            <img :src="item.imageLarge" alt="News" title="News Image" class="img-fluid related-4">
                            <div class="content" :id="'news'+item._id">
                                <h3><a href="javascript:void(0)"  @click="()=> goToDetailPage(item)">{{item.title}}</a></h3>
                                <p v-html="item.description.slice(0,150)"></p>
                                <div class="d-flex align-items-center justify-content-between">
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)">{{item.source}}</a></li>
                                        <li>{{formatNow(item.published,'now',0)}}</li>
                                    </ul>
                                  <!--   <ul class="bookmark d-flex align-items-center">
                                        <li class="me-3 active"><a href="javascript:void(0)"><img src="assets/images/bookmark.png" alt="Icon" title="Bookmark"> </a></li>
                                        <li><a href="javascript:void(0)"><vue-feather type="share-2"></vue-feather></a></li>
                                    </ul> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <no-record v-if=" store.newsList.records.length == 0"></no-record>
                    <div class="col-12 col-xl-5">
                        <div class="card-body d-flex align-items-start mb-3 parentNews" :class="[{'border-bottom':key != 4}]" v-for="item,key in store.newsList.records.slice(1,5)" :key="key">
                            <span class="mb-2 mb-md-0">
                                <!-- <img src="../../../public/assets/images/dummy.webp" alt="News" title="News" v-if="item.image.includes('DEFAULT')" > -->
                                <img :src="item.image" alt="News" title="News">
                            </span>
                            <div class="flex-grow-1 ms-md-3" :id="'news'+item._id">
                                <h6 @click="()=> goToDetailPage(item)"><a href="javascript:void(0)">{{item.title}}</a></h6>
                                <p class="mb-2" v-html="item.description.slice(0,80)"></p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="javascript:void(0)">{{item.source}}</a></li>
                                    <li>{{formatNow(item.published,'now',0)}}</li>
                                    <!-- {{formatNow(item.created)}} -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement slideNews space imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 position-relative">
                         <carousel  :settings="settings" :breakpoints="breakpoints"  v-if="store.trendingNews.totalRecords && store.trendingNews.totalRecords  > 0">
                                <!-- <slide :key="1">
                                    <div class="card-news">
                                        <div class="position-relative hoverNews">
                                            <div class="position-absolute rightSearch d-flex align-items-center justify-content-end">
                                                dhkkdjgdghdkdkfdkdgfdgkd
                                                 <router-link :to="{name : 'all_news',params : {'tab' : 'trending'}}" class="seeAll d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></router-link>
                                             </div>
                                        </div>
                                    </div>
                                </slide> -->
                                <slide v-for="item,key in store.trendingNews.records.slice(0,10)" :key="key">
                                    <div class="card-news" :id="'news'+item._id">
                                        <div class="position-relative hoverNews d-flex justify-content-center align-items-center" v-if="item.details">
                                            
                                            <img width="300" :src="item.details.image" alt="News" title="News">
                                            <!-- <a href="javascript:void(0)" class="position-absolute mb-0" v-html="item.details.description.slice(0,70)"  @click="()=> goToDetailPage(item)" v-if="item.details.description"></a> -->
                                             <a href="javascript:void(0)" class="position-absolute mb-0" v-html="item.details.title.slice(0,70)"  @click="()=> goToDetailPage(item.details)"></a>
                                        </div>
                                    </div>
                                </slide>
                                <template #addons>
                                    <Navigation />
                                    <!-- <pagination /> -->
                                </template>
                            </carousel>
                            <div class="position-absolute rightSearch d-flex align-items-center justify-content-end news-hover-btn">
                        <a href="javascript:void(0)" @click="() => movePage('trending','All')" class="green_btn seeAll d-flex align-items-center">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                    </div>
                    </div>
                </div>

                



                <!-- <div class="row position-relative hoverViewAll" v-if="store.trendingNews.totalRecords && store.trendingNews.totalRecords  > 0">
                    <div class="col-12 col-xl-2 col-md-4 mb-3" v-for="item,key in store.trendingNews.records.slice(0,6)" :key="key">
                        <div class="position-relative hoverNews" v-if="item.details">
                          
                            <img width="300" :src="item.details.image" alt="News" title="News">
                            <a href="javascript:void(0)" class="position-absolute mb-0" v-html="item.details.description.slice(0,70)"  @click="()=> goToDetailPage(item)"></a>
                        </div>
                    </div>
                    <div class="position-absolute rightSearch d-flex align-items-center justify-content-end">
                        <router-link :to="{name : 'all_news',params : {'tab' : 'trending'}}" class="seeAll d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></router-link>
                    </div>
                </div> -->
            </div>
        </section>
        <section class="blockElement newsMarket imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7">
                        <div class="marketTitle">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <h3 class="mb-0">Markets</h3>
                                <a href="javascript:void(0)" @click="() => movePage('All',marketTab)" class="linkBtn d-flex align-items-center">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                            </div>
                            <ul class="toplinks d-flex align-items-center mb-0 pb-2 border-bottom">
                                <!-- <li class="ps-0"><a href="javascript:void(0)" class="active">All</a></li> -->
                                <li class="ps-0"><a href="javascript:void(0)" @click="marketTab = 'Forex'" :class= "[{'active' : marketTab == 'Forex'}]" >Forex</a> </li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Indices'" :class= "[{'active' : marketTab == 'Indices'}]">Indices</a></li>
                                    <li><a href="javascript:void(0)" @click="marketTab = 'Commodities'" :class= "[{'active' : marketTab == 'Commodities'}]">Commodities</a></li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Crypto'" :class= "[{'active' : marketTab == 'Crypto'}]">Crypto</a> </li>
                                <li><a href="javascript:void(0)" @click="marketTab = 'Economy'" :class= "[{'active' : marketTab == 'Economy'}]">Economy</a> </li>
                            </ul>
                        </div>
                        <div class="card-body d-flex align-items-start border-bottom mb-2 px-0" v-for="item,key in tab_ViseNews">
                            
                            <span class="mb-2 mb-md-0"><img :src="item.image" alt="News" title="News"></span>
                            <div class="flex-grow-1 ms-md-3" :id="'news'+item._id">
                                <h6><a href="javascript:void(0)" @click="()=> goToDetailPage(item)">{{item.title}}</a></h6>
                                <p v-html="item.description.slice(0,150)"></p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="javascript:void(0)">{{item.source}} </a></li>
                                    <li>{{formatNow(item.published,'now',0)}}</li>
                                </ul>
                            </div>
                        </div>
                        <no-record v-if=" tab_ViseNews.length == 0"></no-record>
                        
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="marketTitle">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <h3 class="mb-0">Most Read</h3>
                                <a href="javascript:void(0)" @click="() => movePage('mostRead','All')" class="linkBtn d-flex align-items-center me-3">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                            </div>
                        </div>
                        <ul class="mostRead" v-if="store.ReadNews.records">
                            <li v-for="item,key in store.ReadNews.records.slice(0,7)" :key="key" :id="'news'+item._id"><a href="javascript:void(0)" @click="()=> goToDetailPage(item)">{{item.title}}</a></li>
                        </ul>
                        <no-record v-if="store.ReadNews.records == ''"></no-record>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement newsEconomy space imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 mb-4">
                        <h3 class="mb-0">Economy</h3>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-if="store.usNews.totalRecords && store.usNews.totalRecords > 0">
                        <div class="card-body economy position-relative">
                            <h6 class="border-bottom pb-2">United States</h6>
                            <!-- <img src="../../../public/assets/images/dummy.webp" alt="News" title="News" v-if="store.usNews[0].image.includes('DEFAULT')"> -->
                            <img :src="store.usNews.records[0].image" class="img-fluid h-170 my-2" alt="News" title="News">
                            <div class="content" :id="'news'+store.usNews.records[0]._id">
                                <h6><a href="javascript:void(0)" @click="()=> goToDetailPage(store.usNews.records[0])">{{store.usNews.records[0].title}}</a></h6>
                                <p v-html="store.usNews.records[0].description.slice(0,80)"></p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="javascript:void(0)">{{store.usNews.records[0].source}}</a></li>
                                    <li> {{formatNow(store.usNews.records[0].published,'now',0)}}</li>
                                </ul>
                            </div>
                            <div class="inter d-flex align-items-start" v-for="item,key in store.usNews.records.slice(1,3)">
                                <!-- <span class="mb-2 mb-md-0" v-if="item.image.includes('DEFAULT')"><img src="../../../public/assets/images/dummy.webp" alt="News" title="News" height="50" weight="50"></span> -->
                               <!--  <span class="mb-2 mb-md-0"><img :src="item.image" alt="News" title="News"></span> -->
                                <div class="flex-grow-1" :id="'news'+item._id">
                                    <h6 class="f-14"><a href="javascript:void(0)" @click="()=> goToDetailPage(item)">{{item.title}}</a></h6>
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)">{{item.source}}</a></li>
                                        <li>{{formatNow(item.published,'now',0)}}</li>
                                         <!-- {{formatNow(item.created)}} -->
                                    </ul>
                                </div>
                            </div>
                            <a href="javascript:void(0)" @click="() => movePage('All','Economy')"  class="position-absolute linkBtn d-flex align-items-center" v-if="popularTab == 1">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                        </div>      
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-else>
                        <div class="card-body economy">
                            <h6 class="border-bottom pb-2">United States</h6>
                            <no-record></no-record>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-if="store.euNews.totalRecords && store.euNews.totalRecords > 0">
                        <div class="card-body economy position-relative">
                            <h6 class="border-bottom pb-2">Europe</h6>
                            <!-- <img src="../../../public/assets/images/dummy.webp" alt="News" title="News" v-if="store.euNews[0].image.includes('DEFAULT')"> -->
                            <img class="img-fluid h-170 my-2" :src="store.euNews.records[0].image" alt="News" title="News">
                            <div class="content" :id="'news'+store.euNews.records[0]._id"> 
                                <h6><a href="javascript:void(0)" @click="()=> goToDetailPage(store.euNews.records[0])">{{store.euNews.records[0].title}}</a></h6>
                                <p v-html="store.euNews.records[0].description.slice(0,80)"></p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="javascript:void(0)">{{store.euNews.records[0].source}}</a></li>
                                    <li> {{formatNow(store.euNews.records[0].published,'now',0)}}</li>
                                </ul>
                            </div>
                            <div class="inter d-flex align-items-start" v-for="item,key in store.euNews.records.slice(1,3)">
                                <!-- <span class="mb-2 mb-md-0" v-if="item.image.includes('DEFAULT')"><img src="../../../public/assets/images/dummy.webp" alt="News" title="News" height="50" weight="50"></span> -->
                                <!-- <span class="mb-2 mb-md-0"><img :src="item.image" alt="News" title="News"></span> -->
                                <div class="flex-grow-1" :id="'news'+item._id">
                                    <h6 class="f-14"><a href="javascript:void(0)" @click="()=> goToDetailPage(item)">{{item.title}}</a></h6>
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)">{{item.source}}</a></li>
                                        <li>{{formatNow(item.published,'now',0)}}</li>
                                         <!-- {{formatNow(item.created)}} -->
                                    </ul>
                                </div>
                            </div>
                             <a href="javascript:void(0)" @click="() => movePage('All','Economy')" class="position-absolute linkBtn d-flex align-items-center" v-if="popularTab == 1">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-else>
                        <div class="card-body economy">
                            <h6 class="border-bottom pb-2">Europe</h6>
                            <no-record></no-record>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-if="store.asiaNews.totalRecords && store.euNews.totalRecords > 0">
                        <div class="card-body economy position-relative">
                            <h6 class="border-bottom pb-2">Asia Pacific</h6>
                            <!-- <img class="w-100" src="../../../public/assets/images/dummy.webp" alt="News" title="News" v-if="store.asiaNews[0].image.includes('DEFAULT')"> -->
                            <img class="img-fluid h-170 my-2" :src="store.asiaNews.records[0].image" alt="News" title="News">
                            <div class="content" :id="'news'+store.asiaNews.records[0]._id">
                                <h6><a href="javascript:void(0)" @click="()=> goToDetailPage(store.asiaNews.records[0])">{{store.asiaNews.records[0].title}}</a></h6>
                                <p v-html="store.asiaNews.records[0].description.slice(0,80)"></p>
                                <ul class="datTime d-flex align-items-center">
                                    <li class="me-4"><a href="javascript:void(0)">{{store.asiaNews.records[0].source}}</a></li>
                                    <li> {{formatNow(store.asiaNews.records[0].published,'now',0)}}</li>
                                </ul>
                            </div>
                            <div class="inter d-flex align-items-start" v-for="item,key in store.asiaNews.records.slice(1,3)">
                                <!-- <span class="mb-2 mb-md-0" v-if="item.image.includes('DEFAULT')"><img src="../../../public/assets/images/dummy.webp" alt="News" title="News" height="50" weight="50"></span> -->
                               <!--  <span class="mb-2 mb-md-0"><img :src="item.image" alt="News" title="News"></span> -->
                                <div class="flex-grow-1" :id="'news'+item._id">
                                    <h6 class="f-14"><a href="javascript:void(0)" @click="()=> goToDetailPage(item)">{{item.title}}</a></h6>
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)">{{item.source}}</a></li>
                                        <li>{{formatNow(item.published,'now',0)}}</li>
                                         <!-- {{formatNow(item.created)}} -->
                                    </ul>
                                </div>
                            </div>
                            <a href="javascript:void(0)" @click="() => movePage('All','Economy')" class="position-absolute linkBtn d-flex align-items-center" v-if="popularTab == 1">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                        </div>
                    </div>
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0" v-else>
                        <div class="card-body economy">
                            <h6 class="border-bottom pb-2">Asia Pacific</h6>
                            <no-record></no-record>
                        </div>
                    </div>
                    
                    <div class="col-12 col-md-6 col-xl-3 mb-4 mb-xl-0">
                        <div class="card-body economy">
                            <ul class="toplinks sQure pb-2 d-flex align-items-center border-bottom">
                                <li @click="tab = 4;CreateChart('EURUSD')"><a href="javascript:void(0)" :class="[{'active' : tab == 4 }]">Currencies</a> </li>
                                <li class="ps-0" @click="tab = 1;CreateChart('WS30M')"><a href="javascript:void(0)" :class="[{'active' : tab == 1 }]">US</a></li>
                                <li @click="tab = 2;CreateChart('UK100')"><a href="javascript:void(0)" :class="[{'active' : tab == 2 }]">Europe</a> </li>
                                <li @click="tab = 3;CreateChart('J225')"><a href="javascript:void(0)" :class="[{'active' : tab == 3 }]">Asia</a> </li>
                            </ul>
                           <!--  <img class="my-2" src="assets/images/news/chart321.png" alt="News" title="News"> -->
                           <div id="newschartdiv" style="width: 100%; height: 200px;" v-show="store.chartData.length"></div>
                           <div style="width: 100%; height: 120px;" v-show="!store.chartData.length">No data</div>
                            <div class="contentTable table-responsive">
                                <table class="table watchListTable">
                                    <tbody>
                                        <tr v-for="(spreads,index) in priceList">
                                            <td @click="CreateChart(spreads.m)"><strong>{{spreads.other.isinid}}</strong></td>
                                            <td :class="{'red': spreads.buy_increase_staus==0, 'green': spreads.buy_increase_staus==1}">{{COMMON.formatPrice(spreads.B, true)}}</td>
                                           <td :class="{'red': parseFloat(spreads.CS) < 0, 'green': parseFloat(spreads.CS) > 0}">{{parseFloat(parseFloat(spreads.B) - spreads.other.closepx).toFixed(4)}}</td>
                                            <td :class="{'red': parseFloat(spreads.CS) < 0, 'green': parseFloat(spreads.CS) > 0}">{{COMMON.formatPrice(spreads.CS)}}%</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement mostPopular space-footer imageFluid bg-white">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-lg-7 mb-4 mb-lg-0">
                        <div class="marketTitle">
                            <div class="d-flex align-items-center justify-content-between mb-4">
                                <h3 class="mb-0">Most Popular</h3>
                                <a href="javascript:void(0)" @click="() => movePage('popular','All')" class="linkBtn d-flex align-items-center me-3" v-if="popularTab == 1">See All <vue-feather size="15" type="chevron-right"></vue-feather></a>
                            </div>
                            <ul class="toplinks mb-0 d-flex align-items-center pb-2 border-bottom">
                                <li class="ps-0" @click="popularTab = 1"><a href="javascript:void(0)" :class="[{'active' : popularTab == 1 }]">News</a></li>
                                <li  @click="popularTab = 2"><a href="javascript:void(0)" :class="[{'active' : popularTab == 2 }]">Ideas</a> </li>
                            </ul>
                        </div>
                        <div class="barNews" v-if="popularTab == 1 && show_news.length > 0">
                            <div class="card-body d-flex align-items-start border-bottom mb-2 px-0" v-for="item,key in show_news.slice(0,4)" :key="key">
                                <span class="mb-2 mb-md-0"><img class="img-250" width="250" :src="item.details[0].image" alt="News" title="News"></span>
                                <div class="flex-grow-1 ms-md-3" :id="'news'+item._id">
                                    <h6><a href="javascript:void(0)" @click="()=> goToDetailPage(item.details[0])">{{item.details[0].title}}</a></h6>
                                    <p v-html="item.details[0].description.slice(0,100)+'...'"></p>
                                    <ul class="datTime d-flex align-items-center">
                                        <li class="me-4"><a href="javascript:void(0)">{{item.details[0].source}}</a></li>
                                        <li>{{formatNow(item.details[0].published,'now',0)}}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <no-record v-if="popularTab == 1 && show_news.length == 0"></no-record>
                        <div v-if="popularTab == 2 && show_Ideas.length > 0">
                            <div class="card border-0 mb-4" v-for="item,key in show_Ideas.slice(0,4)">
                                <div class="card-body">
                                    <h4 class="card-title">
                                      <a href="#">{{item.title}}</a>
                                      <span class="modifyDate">{{formatNow(item.published,'now',0)}}</span>
                                    </h4>
                                      <img src="assets/images/graph.jpg" class="img-responsive" alt="">
                                    <div class="d-flex align-items-center justify-content-between ideas-currency-row my-3">
                                      <div class="forexnood d-flex align-items-center">
                                        <img src="assets/images/forexnoob.jpg" alt=""> <span class="mx-2">{{item.firstname || ''}} {{item.lastname || ''}}</span> <img src="assets/images/badge.jpg" alt="">
                                      </div>     
                                      <div class="currency-wrap">
                                        <span class="currency"><a href="#">{{item.symbol || 'N/A'}}</a></span>
                                        <span class="monthly"><a href="#">{{item.time_interval || 'N/A'}}</a></span>
                                        <span class="short"><a href="#" class="redBG"><i data-feather="arrow-down-right" ></i>{{item.position || 'N/A'}} </a></span>
                                      </div>
                                    </div>
                                    <p>{{item.description}}</p>
                                    <div class="smallbtn d-flex align-items-center">
                                      <span class="cardEelement">{{item.classification}}</span>   
                                      <span class="cardEelement">{{item.type}}</span> 
                                    </div>
                                    <div class="btm-row d-flex align-items-center justify-content-between border-top mt-4 pt-3">
                                      <div>
                                        <span><i class="fa fa-heart-o" aria-hidden="true"></i>{{item.likes_count || 0}}</span>
                                      </div>
                                      <div class="righticons">
                                        <span><i class="fa fa-comments-o" aria-hidden="true"></i>{{item.comment_count || 0}}</span>
                                        <span><i class="fa fa-share-alt" aria-hidden="true"></i>{{item.share_count || 0}}</span>
                                        <span><i class="fa fa-bookmark-o" aria-hidden="true"></i>{{item.bookmarks_count || 0}}</span>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <no-record v-if="popularTab == 2 && show_Ideas.length == 0"></no-record>
                    </div>
                    <div class="col-12 col-lg-5">
                        <div class="d-flex justify-content-between align-items-center mb-3 mb-md-5">
                          <h3 class="m-0">Economic Calendar</h3>
                          <router-link to="/economic-calendar" class="linkBtn d-flex align-items-center">See All <vue-feather size="12px" type="chevron-right" class="chevron-right"></vue-feather></router-link>
                        </div>
                         <div v-if="economicData.length" class="table-responsive economic">
                            <table v-for="(row, indexOuter) of economicData" class="table table-sm border-0 bg-white" :class="{'border-top': indexOuter > 0}" :key="row.date">
                                <thead>
                                    <tr role="button" @click="()=> toggleOuter(indexOuter)">
                                        <th class="date medium">{{COMMON.formatDate(row.date, "Do MMMM")}}</th>
                                        <th colspan="3" class="events text-end">{{row.items.length}}events</th>
                                    </tr>
                                </thead>
                                <tbody v-if="activeOuter==indexOuter">
                                    <tr
                                        role="button"
                                        v-for="(event, indexInner) of rowInnerList"
                                        @click="()=> toggleInner(indexInner, event)"
                                        class="borderAdd"
                                        :class="{active: activeInner==indexInner, rt: event.importance==1, et: event.importance==2, bt: event.importance==3,}"
                                        :key="event._id"
                                    >
                                        <td class="p-0 border-0" colspan="4">
                                            <table width="100%" class="fixed-layout nowrap">
                                                <tr>
                                                    <td :class="{'active-td': activeInner==indexInner}">
                                                        <span class="d-flex align-items-center">
                                                            <img width="25" height="25" :src=" 'assets/images/country_flag/' + event.country.toLowerCase() + '.webp' " class="rounded-circle me-2" alt="" /><span class="ellipsis">{{event.country}}</span>
                                                        </span>
                                                    </td>
                                                    <td>{{COMMON.formatDate(event.date, "hh:mm a")}}</td>
                                                    <td>{{event.event}}</td>
                                                    <td>
                                                        <a class="d-block text-end" href="javascript:void(0);">
                                                            <vue-feather v-if="activeInner !=indexInner" type="chevron-down"></vue-feather> <vue-feather v-if="activeInner==indexInner" type="chevron-up"></vue-feather>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <tr class="subactive-tr" v-if="activeInner==indexInner">
                                                    <td colspan="4">
                                                        <div class="d-flex align-items-center justify-content-between openDom">
                                                            <span class="subactive-td">
                                                                <div class="f-16">{{COMMON.formatCalendarDate( activeEvent.date, "duration")}}</div>
                                                                <div class="f-12">Time{{COMMON.formatDate( activeEvent.date, "HH:mm")}}</div>
                                                            </span>
                                                            <span align="center" class="active-time-int middle">Actual{{activeEvent.actual ? activeEvent.actual : "-"}}</span>
                                                            <span colspan="2" class="subactive-td2">
                                                                <div class="forecast"><span class="f-12 me-2">Forecast</span> <span class="f-16">{{activeEvent.teForecast ? activeEvent.teForecast : "-"}}</span></div>
                                                                <div class="previe"><span class="f-12 me-2">Previous</span> <span class="f-16">{{activeEvent.previous ? activeEvent.previous : "-"}}</span></div>
                                                            </span>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <no-record v-else></no-record>
                        
                    </div>
                </div>
            </div>
        </section>
        
         <!-- Open Modal -->
        <!-- div class="modal fade ideaModal" v-if="Object.keys(showNews).length > 0" :class="(Object.keys(showNews).length > 0) ? 'show' : ''">
              <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable news-detail-pop">
                
                <div class="row modal-content g-0 h-100">
                    <div class="col-12 col-lg-6 ideaChart">
                        <img class="d-block w-100 h-100" :src="showNews.image" alt="News" title="News Image" >
                    </div>  
                    <div class="col-12 col-lg-6 d-flex flex-column ideaChart border-start">
                        <div class="modal-body p-4 pt-0 border-0">
                            <div class="d-flex align-items-center justify-content-between modalHeader mb-3 py-3">
                                <ul class="d-flex align-items-center mb-0">
                                    <li><a href="javascript:void(0)" class="d-flex me-3"><vue-feather size="23px" type="share-2"></vue-feather></a></li>
                                    <li><a href="javascript:void(0)" class="d-flex "><vue-feather size="23px" type="printer"></vue-feather></a></li>
                                </ul>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="showNews = {}"></button>
                            </div>
                            <ul class="datTime d-flex align-items-center mb-0">
                                <li class="me-4"><a class="green" href="javascript:void(0)">{{showNews.source}}</a></li>
                                <li>{{formatNow(showNews.published,'now',0)}}</li>
                            </ul>
                            <perfect-scrollbar class="scroll-area">
                            <h2 class="medium my-4 py-2" >{{showNews.title}}</h2>
                            <p v-html="showNews.description"></p>
                            </perfect-scrollbar>
                        </div>
                    </div>
                </div>
               
              </div>
        </div> -->
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
import { useMainStore } from "@/store";
import { Const } from "@/plugins/vue-provider-cache/const.mod";
import moment from "moment";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation,  } from 'vue3-carousel';
export default {
    setup() {
        const store = useMainStore();
        return { store };
    },
    data() {
        return {
            showNews: {},
            tab1: false,
            marketTab : 'Forex',
            // usNews  : [],
            // EuropeData : [],
            // AsiaData : [],
            activeOuter: 0,
            activeInner: 0,
            //activeEvent: {},
            europeArr : ["100GBP","F40EUR","D30EUR","E50EUR","E35EUR"],
            asiaArr : ["225JPY","200AUD","H50HKD"],
            USArr : ["U30USD","SPXUSD","NASUSD"],
            currencyArr : ["EURUSD","EURGBP","GBPUSD","GBPJPY","USDCAD","USDCHF","USDJPY","AUDUSD","AUDCAD","NZDUSD"],
            tab: 4,
            priceType: {
                1: "getIndicesAll",
                2: "getIndicesAll",
                3: "getIndicesAll",
                4: "getCurrencies",
            },
            popularTab : 1,
            currency : 'WS30M',
            settings: {
                itemsToShow: 1,
                snapAlign: 'center',
            },
            breakpoints: {
                // 700px and up
                767: {
                  itemsToShow: 2,
                  snapAlign: 'center',
                },
                1024: {
                  itemsToShow: 3,
                  snapAlign: 'start',
                },
                1199: {
                  itemsToShow: 5,
                  snapAlign: 'start',
                },
            },
        }
    },
    // watch : {
    //     'showNews'(val){
    //         const el = document.body;
    //         if(Object.keys(val).length > 0){
    //             if (el.classList.contains('position-fixed')) {
    //               el.classList.remove('position-fixed');
    //             } else {
    //               el.classList.add('position-fixed');
    //             }
    //         }else{
    //             el.classList.remove('position-fixed');
    //         }
    //     }
    // },
    computed:{
        tab_ViseNews(){
            if(this.marketTab == 'Forex' && Object.keys(this.store.ForexNews).length && this.store.ForexNews['records']){
                return this.store.ForexNews['records'].slice(0,2)
            }else if(this.marketTab == 'Crypto' && Object.keys(this.store.CryptoNews).length && this.store.CryptoNews['records']){
                return this.store.CryptoNews['records'].slice(0,2)
            }else if(this.marketTab == 'Indices' && Object.keys(this.store.IndicesNews).length && this.store.IndicesNews['records']){
                return this.store.IndicesNews['records'].slice(0,2)
            }else if(this.marketTab == 'Commodities' && Object.keys(this.store.CommoditiesNews).length && this.store.CommoditiesNews['records'] ){
                return this.store.CommoditiesNews['records'].slice(0,2)
            }else if(this.marketTab == 'Economy' && Object.keys(this.store.alleconomyNews).length && this.store.alleconomyNews['records']){
                return this.store.alleconomyNews['records'].slice(0,2)
            }else{
                return []
            }
        },
        dateAdjustedEvents() {
          if (Object.keys(this.store.calendarData).length) {
            let temp = [],
              allEvents = [];
            for (let key in this.store.calendarData) {
              this.store.calendarData[key].items.map((i) => {
                let obj = Object.assign({}, i);
                obj.date = this.COMMON.formatCalendarDate(
                  obj.date,
                  "YYYY-MM-DD HH:mm",
                  0
                );
                //console.log(obj);
                temp.push(obj);
              });
            }
            temp.map((i, index) => {
              const ind = allEvents.findIndex(
                (j) =>
                  moment(j.date).format("YYYY-MM-DD") ==
                  moment(i.date).format("YYYY-MM-DD")
              );
              if (ind > -1) {
                if (allEvents[ind].items) {
                  allEvents[ind].items.push(i);
                } else {
                  allEvents[ind].items = [i];
                }
              } else {
                allEvents.push({
                  date: moment(i.date).format("YYYY-MM-DD"),
                  items: [i],
                });
              }
            });
            return allEvents;
          } else {
            return [];
          }
        },
        economicData() {
          if (this.dateAdjustedEvents.length) {
            let arr = [];
            this.dateAdjustedEvents.map((i) => {
              let temp = {
                date: i.date,
                items: [],
              };
              if (i.items.length) {
                temp.items = i.items.filter((j) => {
                  return (
                    moment(
                      this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                    ).diff(moment(), "minute") > 5
                  );
                });
              }
              if (temp.items.length) arr.push(temp);
            });
            return arr.slice(0, 5);
            // return arr;
          } else {
            return [];
          }
        },
        rowInnerList() {
          if (
            this.activeOuter > -1 &&
            this.economicData[this.activeOuter].items &&
            this.economicData[this.activeOuter].items.length
          ) {
            let arr = [];

            arr = this.economicData[this.activeOuter].items.filter((j) => {
              return (
                j.importance > 1 &&
                moment(
                  this.COMMON.formatDate(j.date, "YYYY-MM-DD HH:mm")
                ).diff(moment(), "minute") > 5
              );
            });
            return arr.slice(0, 5);
          } else {
            return [];
          }
        },
        activeEvent() {
          if (this.activeOuter > -1 && this.activeInner > -1)
            return this.rowInnerList[this.activeInner];
        },
        show_news(){
            if(Object.keys(this.store.popularNews).length && this.store.popularNews.records.length){
                return this.store.popularNews.records.filter(val =>{
                    if(Array.isArray(val.details)){ return val.details.length  > 0}
                })
            }else{
                return []
            }
        },
        show_Ideas(){
            if(Object.keys(this.store.popularNews).length && this.store.popularIdeas.totalRecords > 0){
                return this.store.popularIdeas.records.splice(0,3)
            }else{
                return []
            }
        },
        currencies_list() {
          try {
            this.$store.getters[Const.GET_HEARTBEATS]["priceFeed"];
            return this.$store.getters[Const.GET_PROVIDERS]["priceFeed"].iCache[
              this.priceType[this.tab]
            ]();
          } catch (ex) {
            return {};
          }
        },
        priceList() {
            if(this.tab == 1){
                let arr = Object.values(this.currencies_list)
                return arr.filter(i => this.USArr.includes(i.other.isinid));
            }else if(this.tab == 2){
                let arr = Object.values(this.currencies_list)
                return arr.filter(i => this.europeArr.includes(i.other.isinid));
            }else if(this.tab == 3){
                let arr = Object.values(this.currencies_list)
                return arr.filter(i => this.asiaArr.includes(i.other.isinid));
            }else if(this.tab == 4){
                let arr = Object.values(this.currencies_list)
                return arr.filter(i => this.currencyArr.includes(i.other.isinid));
            }
        },
    },
    methods:{
        toDivElement(id){
            try{
                setTimeout(function(){
                    var elmnt = document.getElementById(id);
                    elmnt.scrollIntoView({behavior: "smooth"});
                    document.getElementById(id).focus();
                    //var top = $(window).scrollTop()
                    // console.log(id,'top',top)
                    // $(window).scrollTop(top-80)
                }, 500);
            }catch(e){}
        },
        goToDetailPage(item){
            let title = item.title
            if(title.includes('-')){
                title = title.replace(/-+/g, '')
            }else{
                let titl = title.replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-')
                if(titl.includes('--')){
                    title = titl.replace(/--+/g, '-')
                }else{
                    title = item.title
                }
            }
            this.$router.push({
                name: 'news_details',
                params : {'id' : item._id,'title':title.toLowerCase().replace(/[&\/\\#,+()$~%.'":*?<>{}“” ]/g, '-'),'page' : 1}
            });
        },
        movePage(type,news){
            this.$router.push({
                path: '/all-news',
                query: {type :type,news: news},
              });
        },
        toggleOuter(index) {
          if (this.activeOuter == index) {
            this.activeOuter = -1;
            this.activeInner = -1;
          } else {
            this.activeOuter = index;
            this.activeInner = -1;
          }
        },
        toggleInner(index, event) {
          if (this.activeInner == index) {
            this.activeInner = -1;
            this.activeEvent = {};
          } else {
            this.activeInner = index;
            this.activeEvent = event;
          }
        },
        us_news(){
            if(this.store.economyNews.length > 0){
                this.USData = this.store.economyNews.records.filter(val => val.country == 'United States')
                this.EuropeData = this.store.economyNews.records.filter(val => val.country == 'Europe')
                this.AsiaData   = this.store.economyNews.records.filter(val => val.country == 'Asia Pacific')
            }
        },
        formatNow(date,format,offset){
            if(!date) return 'N/A'
            let d = moment(date, 'YYYY-MM-DD HH:mm:ss')
            let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
            d.add(tz, 'minutes')
            return d.fromNow()
        },
        formatDate(val){
            return moment(val).format('DD MMM')
        },
        allnews(){
            let formData = {
                page: 1,
                perPage: 15
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        forexNews(){
            let formData = {
                page: 1,
                perPage: 15,
                category_type : 'FOREX'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        cryptoNews(){
            let formData = {
                page: 1,
                perPage: 15,
                category_type : 'CRYPTO'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        indicesNews(){
            let formData = {
                page: 1,
                perPage: 15,
                category_type : 'INDICES'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        commodityNews(){
            let formData = {
                page: 1,
                perPage: 15,
                category_type : 'COMMODITIES'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        bondNews(){
            let formData = {
                page: 1,
                perPage: 15,
                category_type : 'ECONOMY'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        mostRead(){
            let formData = {
                page: 1,
                perPage: 15,
                type : 'most_read'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        getTrendingNews(){
            let formData = {
                page: 1,
                perPage: 15,
            };
            this.store.callTrendingNews(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        // economyNews(){
        //     let formData = {
        //         page: 1,
        //         perPage: 15,
        //         country : ['United States','Europe','Asia Pacific']
        //     };
        //     this.store.callNewsList(formData,false).then(() =>{
        //         this.us_news()
        //     }).catch( err => console.log(err.message))
        // },
        useconomyNews(){
            let formData = {
                page: 1,
                perPage: 15,
                economy_country : 'US'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        eureconomyNews(){
            let formData = {
                page: 1,
                perPage: 15,
                economy_country : 'Europe'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        apeconomyNews(){
            let formData = {
                page: 1,
                perPage: 15,
                economy_country : 'Asia'
            };
            this.store.callNewsList(formData,false).then(() =>{
            }).catch( err => console.log(err.message))
        },
        getCalendar(){
            let formData = {
              page: 1,
              perPage: 15,
            };
            this.store
          .getCalendar(formData, false)
          .then(() => {
          })
          .catch();
        },
        getPopularIdeas(){
           let formData = {
              page: 1,
              perPage: 3,
            };
            this.store
          .callPopularIdeas(formData, false)
          .then(() => {
          })
          .catch();
        },
        getPopularNews(){
            let formData = {
              page: 1,
              perPage: 3,
            };
            this.store
          .callPopularNews({}, false)
          .then((response) => {
          })
          .catch();
        },
        calnewsDetail(){
            let form ={
                '_id' : this.showNews._id
            }
            this.store
          .callNewsDetails(form, false)
          .then((response) => {
          })
          .catch();
        },
        formatDate(data) {
            let now = new Date(data);
            let year = "" + now.getFullYear();
            let month = "" + (now.getMonth() + 1); if (month.length == 1) { month = "0" + month; }
            let day = "" + now.getDate(); if (day.length == 1) { day = "0" + day; }
            let hour = "" + now.getHours(); if (hour.length == 1) { hour = "0" + hour; }
            let minute = "" + now.getMinutes(); if (minute.length == 1) { minute = "0" + minute; }
            return year + month + day + hour + minute;
        },
        getOnDayData(){
            var prev = new Date();
            prev.setFullYear(prev.getFullYear());
            let onePev = new Date(prev);
            onePev.setDate(onePev.getDate()-1);
            let link = process.env.VUE_APP_LIVE_CHART_URL;
            let formData = {
                isin: this.currency,
                // startdate: this.formatDate(onePev),
                // enddate: this.formatDate(onePev),
                startdate: moment().subtract(2, 'day').startOf('day').format('YYYYMMDDHHMM'),
                enddate: moment().endOf('day').format('YYYYMMDDHHMM'),
                interval: "15m",
                link: link,
            };
          this.store
            .getChartData(formData, false)
            .then((response) => {
                am5.ready(() => {
                    let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'newschartdiv');
                    d?.dispose();
                     this.DrawChart()
                });
            })
            .catch();
        },
        CreateChart(m){
            this.currency = m;
            this.getOnDayData()
        },
        DrawChart(){
        var root = am5.Root.new("newschartdiv");
        root.interfaceColors.set("grid", am5.color(0xffffff));
        root.setThemes([
          am5themes_Animated.new(root)
        ]);

        root.dateFormatter.setAll({
          // dateFormat: "minute",
          dateFields: ["valueX"]
        });
        var data = this.store.chartData;
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
          // focusable: true,
          // panX: true,
          // panY: true,
          // wheelX: "panX",
          // wheelY: "zoomX",
          // pinchZoomX:true
        }));

        //var easing = am5.ease.linear;
        // Create axes
        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
           maxDeviation: 0.1,
           groupData: false,
           visible : false,
          baseInterval: {
            timeUnit: "minute",
            count: 15
          },
          renderer: am5xy.AxisRendererX.new(root, {

          }),
          tooltip: am5.Tooltip.new(root, {})
        }));

        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
          maxDeviation: 0.2,
          renderer: am5xy.AxisRendererY.new(root, {
            inside: true
          })
        }));


        // Add series

        var series = chart.series.push(am5xy.LineSeries.new(root, {
          minBulletDistance: 10,
          // connect: false,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "close",
          valueXField: "time",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{valueY}"
          })
        }));

        series.fills.template.setAll({
          fillOpacity: 0.2,
          visible: true
        });

        series.strokes.template.setAll({
          strokeWidth: 2
        });


        // Set up data processor to parse string dates

        series.data.processor = am5.DataProcessor.new(root, {
          dateFormat: "yyyy-MM-dd HH:mm",
          dateFields: ["time"]
        });
        series.data.setAll(data);

        series.bullets.push(function() {
          var circle = am5.Circle.new(root, {
            radius: 4,
            fill: root.interfaceColors.get("background"),
            stroke: series.get("fill"),
            strokeWidth: 2
          })

          return am5.Bullet.new(root, {
            sprite: circle
          })
        });


        // Add cursor

        // var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        //   xAxis: xAxis,
        //   behavior: "none"
        // }));
        //cursor.lineY.set("visible", false);

        // add scrollbar
        // chart.set("scrollbarX", am5.Scrollbar.new(root, {
        //   orientation: "horizontal"
        // }));
        chart.zoomOutButton.set("forceHidden", true);

        // Make stuff animate on load
        chart.appear(1000, 100);
        }
    },
    mounted(){
        if(this.$router.options.history.state.forward && this.$router.options.history.state.forward.split('/')[1] == 'news-detail'){
            let data = this.$router.options.history.state.forward.split('/');
            this.toDivElement('news'+data[2])
            this.$router.options.history.state.forward = ""
            this.page = 1
        }
    },
    created(){
        this.getOnDayData();
        this.allnews()
        this.forexNews()
        this.indicesNews()
        this.commodityNews()
        this.cryptoNews()
        this.bondNews()
        this.mostRead()
        this.useconomyNews()
        this.eureconomyNews()
        this.apeconomyNews()
        //this.economyNews()
        this.getCalendar()
        this.getPopularIdeas()
        this.getPopularNews()
        this.getTrendingNews()
    },
    components: {
        bannerSlider:bannerslider,
        Carousel,
        Slide,
        Navigation,

    },     
}
</script>
<style >
.scroll-area {
  position: relative;
  margin: auto;
  width: 100%;
  height: calc(100% - 100px);
}
</style>